<template>
  <div class="main-footer">
    <div class="main-footer__block">
      <IconComponent name="logo" />
      <ul v-if="socials && socials.length" class="main-footer__block-socials">
        <li v-for="(item, i) in socials" :key="i" class="main-footer__block-socials-item">
          <a :href="item.link" target="_blank" rel="nofollow">
            <!--            <figure v-html="item.icon" />-->
            <ImgComponent :img="item.icon" width="50" height="50" contain />
          </a>
        </li>
      </ul>
    </div>
    <div class="main-footer--middle">
      <div v-if="for_customers_documents.length" class="main-footer__block">
        <h4>Для покупателя</h4>
        <ul class="main-footer__block-links">
          <li v-for="(item, i) in for_customers_documents" :key="i">
            <router-link :to="{ name: 'static', params: { id: item.id } }">{{ item.title }}</router-link>
          </li>
        </ul>
      </div>
      <div v-if="company_documents.length" class="main-footer__block">
        <h4>Компания</h4>
        <ul class="main-footer__block-links">
          <li v-for="(item, i) in company_documents" :key="i">
            <router-link :to="{ name: 'static', params: { id: item.id } }">{{ item.title }}</router-link>
          </li>
        </ul>
      </div>
      <div v-if="partners_documents.length" class="main-footer__block">
        <h4>Партнерам</h4>
        <ul class="main-footer__block-links">
          <li v-for="(item, i) in partners_documents" :key="i">
            <router-link :to="{ name: 'static', params: { id: item.id } }">{{ item.title }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="main-footer__block main-footer__block--contacts">
      <a :href="`tel:+7 906 446 19 16`" class="main-footer__block--number">+7 906 446 19 16</a>
      <span class="main-footer__block--schedule"> Время доставки с 10:00 до 22:00 </span>
      <router-link :to="{ name: 'about' }" class="btn btn--main-hollow btn--md">Обратная связь</router-link>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "MainFooterComponent",
  components: { ImgComponent, IconComponent },
  data() {
    return {
      companyInfo: [{ link: "about", text: "Магазины" }],
      forBuyer: [
        { link: "/static/documents/Как-мы-работаем.pdf", text: "Как мы работаем" },
        { link: "/static/documents/Оплата.pdf", text: "Оплата" },
        { link: "/static/documents/Доставка.pdf", text: "Доставка" },
        { link: "/static/documents/Обмен-и-возврат.pdf", text: "Обмен и возврат" },
      ],
    };
  },
  computed: {
    socials() {
      return this.$store.state.global.socials || [];
    },
    for_customers_documents() {
      return this.$store.state.global.for_customers_documents;
    },
    company_documents() {
      return this.$store.state.global.company_documents;
    },
    partners_documents() {
      return this.$store.state.global.partners_documents;
    },
  },
};
</script>

<style lang="stylus">
.main-footer{
  display grid
  grid-template-columns auto 1fr auto
  gap 100px
  width: 100%;
  //gap 100px
  max-width var(--main-width)
  margin-top: auto;
  padding 64px 0
  +below(1200px) {
    padding 32px 0
  }
  +below(990px) {
    gap: 32px
  }
  +below(860px) {
    padding 16px 0
    grid-template-columns auto auto
  }
  +below(640px) {
    grid-template-columns 1fr
    gap: 24px
  }

  .logo-icon {
    height: 26px;
  }

  &--middle {
    display flex
    justify-content space-around
    gap: 32px
    +below(860px) {
      justify-content space-between
    }
    +below(640px) {
      justify-content flex-start
      gap: 24px
    }
    +below(440px) {
      flex-wrap wrap
    }
  }

  &__block {
    display flex
    flex-direction column
    +below(860px) {
      align-items flex-start
    }

    &:first-child {
      +below(860px) {
        grid-column span 2
      }
      +below(640px) {
        grid-column auto
      }
    }

    &--contacts {
      +above(641px) {
        align-items flex-end
      }
    }

    .btn--md{
      padding: 12px 37px;
      height 46px
    }
  }

  &__block-socials{
    display flex
    margin-top 34px
    gap 20px

    &-item{
      display flex
      justify-content: center;
      align-items: center;

      a {
        display flex
        justify-content: center;
        align-items: center;
        width: 36px;
        height: 36px;
        border-radius 50%
        overflow hidden

        &:hover {
          background var(--green-50)
        }
      }

      svg path{
        fill var(--gray-3)
      }
    }
  }

  &__block-links {
    display flex
    flex-direction column
    gap 2px
    li {
      a {
        font-weight: 400;
        font-size: 0.85em;
        text-decoration none
        line-height: 20px;
        color: var(--gray-2);

        &:hover {
          color var(--main)
        }
      }
    }
  }
  &__block--number{
    display flex
    font-weight: 700;
    font-size: 1.5em;
    line-height: 33px;
    text-align: justify;
    color: var(--gray-dark);
  }
  &__block--schedule{
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--dark);
    padding 8px 0 16px 0
    text-align: right
    +below(640px) {
      text-align left
    }
  }
}
</style>
