<template>
  <footer class="footer">
    <div class="container-padding">
      <div class="footer__inner">
        <MainFooterComponent />
        <SmallFooterComponent />
      </div>
    </div>
  </footer>
</template>

<script>
import MainFooterComponent from "components/footer/components/MainFooterComponent.vue";
import SmallFooterComponent from "components/footer/components/SmallFooterComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
export default {
  name: "FooterComponent",
  components: { SectionComponent, SmallFooterComponent, MainFooterComponent },
};
</script>

<style lang="stylus">
.footer {
  display flex
  width: 100%;
  margin-top: auto;

  &__inner {
    display flex
    flex-direction column
  }
}
</style>
