<template>
  <div class="small-footer">
    <section class="small-footer__documents">
      <span class="small-footer__title">Официальный сайт сети супермаркетов «Зеленое Яблоко»</span>
      <span>|</span>
      <div v-for="(item, i) in footer_documents" :key="i" class="small-footer__wrapper">
        <a @click.prevent="goToLink(item.id)" href="#">{{ item.title }}</a>
        <span v-if="footer_documents.length !== i + 1">|</span>
      </div>
    </section>
    <!--    <section class="small-footer__pay-method">-->
    <!--      <IconComponent name="visa" />-->
    <!--      <IconComponent name="mastercard" />-->
    <!--      <IconComponent name="mir" />-->
    <!--    </section>-->
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "SmallFooterComponent",
  components: { IconComponent },
  methods: {
    goToLink(id) {
      this.$router.push({ name: "static", params: { id: id } });
    },
  },
  computed: {
    footer_documents() {
      return this.$store.state.global.footer_documents;
    },
  },
};
</script>

<style lang="stylus">
.small-footer{
  display flex
  padding 24px 0
  justify-content: space-between;
  gap 100px
  +below(640px) {
    padding 16px 0
  }

  &__documents {
    display flex
    flex-wrap wrap
    gap 24px

    span {
      font-weight: 400;
      font-size: 0.75em;
      line-height: 20px;
      color: var(--gray-700);

      &:not(.small-footer__title) {
        +below(440px) {
          display none
        }
      }
    }

    a {
      font-weight: 400;
      font-size: 0.75em;
      line-height: 20px;
      color: var(--gray-700);
      text-decoration underline

      &:hover {
        color var(--main)
      }
    }
  }

  &__wrapper {
    display flex
    gap 18px
  }

  &__pay-method{
    display flex
    gap 34px

    .icon svg{
      width: 35px;
    }
  }
}
</style>
