<template>
  <section class="section">
    <div class="container-padding">
      <div class="section-inner">
        <slot />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SectionComponent",
};
</script>

<style lang="stylus">
.section {
  &-inner {
    display flex
    flex-direction column
    gap: var(--gap)
  }
}
</style>
